.add-lead{
    .rows{
        display: flex;
        flex-wrap: wrap;
        .label{
            width: 150px;
        }
        .input{
            width: auto;
            flex-grow: 1;
        }
    }
}