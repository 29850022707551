.order-container {
  .order-item {
    background-color: #faf7f9;
    border-radius: 40px;
    box-shadow: 5px 10px 8px #dcdcdc;
  }
  .order-item2 {
    background-color: #faf7f9;
    border-radius: 40px;
    box-shadow: 5px 10px 8px #dcdcdc;
  }
}

.rmdp-container {
  width: 100%;
}

.blink-message {
  width: 10px;
  height: 10px;
  top: 5px;
  animation-name: animate-blink-message;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes animate-blink-message {
  50% {
    background-color: rgb(253, 57, 57);
  }
  100% {
    background-color: transparent;
  }
}

.cursor-pointer {
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  .box {
    flex-wrap: wrap !important;
  }
  .box div {
    width: 100% !important;
    height: auto;
    text-align: center;
  }
  .box-b div {
    width: 100% !important;
    // flex-wrap: wrap !important;
    height: 67px !important;
    //margin-top: 50px !important;
    margin-right: 5px !important;
  }
  .bank {
    width: 99% !important;
    height: 67px !important;
    margin-top: 0px !important;
    margin-right: 0px !important;
  }
}
.addOrder {
  .label {
    width: 43% !important;
  }
  .input {
    width: 60% !important;
  }
  .labelAddress {
    width: 18% !important;
  }
  .inputAddress {
    width: 82% !important;
  }
  .labelReceptor {
    width: 20%;
  }
  .inputReceptor {
    width: 82%;
  }
  .labelDesc {
    width: 25% !important;
  }
  .inputDesc {
    width: 75% !important;
  }
  @media only screen and(max-width:992px) {
    .labelReceptor {
      width: 42% !important;
    }
    .inputReceptor {
      width: 62% !important;
    }
  }
  @media only screen and(max-width:640px) {
    .pr {
      flex-wrap: wrap !important;
    }
    .container div {
      flex-direction: column !important;
    }

    .container-responsive {
      flex-wrap: wrap !important;
    }
    .label,
    .input,
    .input2,
    .labelAddress,
    .inputAddress,
    .labelDesc,
    .inputDesc,
    .inputReceptor,
    .labelReceptor {
      width: 100% !important;
    }
    .order-item2 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
  .label-desc {
    flex-direction: column !important;
  }
}
@media only screen and(max-width:440px) {
  .box-btn {
    flex-wrap: wrap !important;
  }
  .btn {
    // width: 100% !important;
  }
  .container-responsive2 {
    flex-direction: column !important;
  }
  .label,
  .input {
    flex-wrap: wrap !important;
    width: 100% !important;
  }
  .box-img img {
    width: 120px !important;
    height: 120px !important;
  }
  .box-desc {
    flex-direction: column !important;
  }
  .box-desc span {
    margin-right: 20px !important;
  }
  .box-p {
    flex-direction: column !important;
  }
  // .box-txt {
  //   margin-right: -20px !important;
  //   flex-direction: column !important;
  //   //text-align: justify !important;
  // }
}
.paid-button,
.accordion-button,
.buttons {
  width: 30%;
}
@media only screen and(max-width:600px) {
  .admin-button {
    flex-wrap: wrap-reverse;
  }
  .admin-button div {
    margin-bottom: 10px;
  }
  .paid-button {
    width: 100%;
  }
  .paid-button span {
    margin-left: auto;
    margin-right: auto;
  }
  .accordion-button,
  .buttons {
    width: 40%;
  }
}
.two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
