.home {
  direction: rtl !important;

  .container-item {
    width: 100%;
    .item {
      padding: 0;
      margin: 10px 7px;
      // width: 273px;
      border-radius: 20px;
      display: flex;
      flex-flow: column;
      align-content: space-between !important;
      .item-title {
        padding: 15px;
      }
      a {
        text-decoration: none !important;
      }
    }
    .service-img {
      height: 138px;
      width: 138px;
    }
    .bottom-line {
      width: 100%;
      height: 20px;
      border-radius: 10px;
      background-color: #203b8c;
    }
    @media (max-width: "990px") {
      .service-img {
        height: 100px;
        width: 100px;
      }
      .item {
        font-size: 14px;
        margin: 10px 6px;
      }
      .service-title {
        font-size: 16px;
      }
    }
    @media (max-width: "800px") {
      .service-img {
        height: 80px;
        width: 80px;
      }
      .item {
        font-size: 12px;
      }
      .service-title {
        font-size: 14px;
      }
    }
    @media (max-width: "700px") {
      .service-img {
        height: 70px;
        width: 70px;
      }
      .item {
        font-size: 10px;
        margin: 10px 1px;
      }
      .service-title {
        font-size: 12px;
      }
    }

    @media (max-width: "600px") {
      display: none !important;
    }
  }
  .container-item-sm {
    width: 98%;
    margin: 0 auto;
    border-radius: 30px;
    height: 180px;
    .item {
      padding: 0;
      width: 30%;
      display: flex;
      flex-flow: column;
      align-content: space-between !important;
      .item-title {
        padding: 15px;
        h5 {
          margin-top: 50px;
          color: black !important;
          font-size: 10px;
        }
      }
      a {
        text-decoration: none !important;
      }
    }
    .bottom-line {
      width: 100%;
      height: 20px;
      border-radius: 10px;
      background-color: #203b8c;
    }
    @media (max-width: "600px") {
      display: flex !important;
    }
  }

  .display-container-item2,
  .display-container-item3 {
    @media (max-width: "600px") {
      display: none !important;
    }
  }
  .display-input {
    @media (max-width: "992px") {
      display: block !important;
    }
  }
  .container-item2-sm {
    @media (max-width: 600px) {
      display: block !important;
    }
  }

  .about-us {
    padding: 20px;
    height: auto;
    background-image: url("./images/oil/car1.webp");
    background-image: linear-gradient(#203b8c52);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .container-item4 {
    .item {
      width: 338px;
      border-radius: 20px;
      background-color: white;
      margin-top: 70px;
      margin: 70px 0 5px 5px;
    }
    .main-text {
      width: 100%;
      height: 246px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
    }
    .main-title4 {
      width: 95%;
      height: 178px;
      top: 90px;
      border-radius: 30px;
      background-image: linear-gradient(to right, #1e2f64 36%, #203b8c 50%);
    }
    @media (max-width: "600px") {
      display: none;
    }
  }
  .container-item4-sm {
    .item {
      margin: 0 auto;
      width: 70%;
      border-radius: 20px;
      background-color: white;
    }
    .main-text {
      width: 100%;
      height: 246px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
    }
    .main-title-s-4 {
      width: 95%;
      height: 178px;
      top: 60px;
      border-radius: 30px;
      background-image: linear-gradient(to right, #1e2f64 36%, #203b8c 50%);
    }
    @media (max-width: "600px") {
      display: block !important;
    }
  }
  .title-color {
    // color: #d3a40c;
    color: #203b8c;
  }
  .header-title {
    height: auto;
    .main-img {
      width: 100%;
    }
  }
  .btn-join {
    width: 43%;
    height: 50px;
  }
  @media (max-width: 992px) {
    .title-color {
      font-size: 20px !important;
    }
    .title-text {
      font-size: 11px !important;
    }
    .header-title {
      display: block !important;
      height: auto;
      .main-img {
        width: 90%;
      }
    }
  }

  @media (max-width: 700px) {
    .text-width {
      width: 80% !important;
    }
    .header-title {
      // height: auto;
      .main-img {
        width: 90%;
      }
    }
    .btn-join {
      width: 150px;
      height: 40px;
    }
  }
  @media (max-width: 600px) {
    .display-GroupView {
      display: none !important;
    }
  }
  @media (max-width: 500px) {
    .title-color {
      font-size: 14px !important;
    }
    .header-title {
      // height: auto;
      .main-img {
        width: 70%;
      }
    }
    .title-text {
      font-size: 8px !important;
    }
    .btn-join {
      width: 100px;
      height: 30px;
      font-size: 10px !important;
    }
  }
}
