.clue{
.rmdp-container .rmdp-input {
  background-color: #D9DEDA ;
  outline: none;
  border: none;
  margin: 0px 0px 13px 0 ;
  border-radius: 13px ;
  height: 47px ;
  width: 300px !important;
  padding: 10px ;
  text-align: right;
}
.dateBox2 {
  width: 300px !important ;
}
.dateBox2 .rmdp-container {
  width: 300px !important;
}
@media (max-width: 503px) {
  .rmdp-container .rmdp-input {
    width: 230px !important;
  }
  .dateBox2 {
    width: 230px !important ;
  }
  .dateBox2 .rmdp-container {
    width: 230px !important;
  }
}
@media (max-width: 425px) {
  .rmdp-container .rmdp-input {
    width: 100% !important;
  }
  .dateBox2 {
    width: 100% !important;
  }
  .dateBox2 .rmdp-container {
    width: 100% !important;
  }
}
}