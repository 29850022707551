// .addproduct-style {
.fs-14 {
  font-size: 14px;
}
// .modal-style {
//   width: 100% !important;
//   .modal-dialog {
//     max-width: 1500px !important;
//     width: 95% !important;
//   }
// }
#discountDate,
#discountDatePartner {
  // width: 150px !important;
  height: 36px !important;
}
.validation-txt {
  font-size: 12px;
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
.main-price-item {
  width: 33.33% !important;
}
.rmdp-container {
  width: 100% !important;
}
.color-container,
.inventory-container {
  width: 47%;
}
.nav-link.active {
  color: rgb(28, 28, 189) !important;
}
.nav-link {
  color: rgb(45, 45, 46) !important;
}
.title1,
.brand,
.cover,
.other-media {
  width: 50%;
}

@media only screen and(max-width:500px) {
  .price-date {
    flex-wrap: wrap;
  }

  .color-container {
    width: 100%;
  }
  .inventory-container {
    width: 90%;
  }
  #discountDate,
  #discountDatePartner {
    width: 100% !important;
  }
  .main-price-item {
    width: 100% !important;
  }
  .first-container,
  .second-container {
    flex-wrap: wrap;
  }
  .title1,
  .brand,
  .cover,
  .other-media {
    width: 100%;
  }
}
// }
@media screen and (max-width: 992px) {
  .addproduct-style {
    .modal-style {
      width: 100% !important;
    }
    .modal-dialog {
      max-width: 1500px !important;
      width: 95% !important;
    }
  }
}
