$pixel: 10px;
$percent: 10%;
$red: #ef394e;
.store {
  .display-input {
    @media (max-width: "992px") {
      display: block !important;
    }
  }
  .ad-slider {
    width: $percent * 7.5;
    height: $pixel * 36.5;

    @media only screen and (max-width: 1100px) {
      width: $percent * 10;
      padding-left: $pixel;
      padding-right: $pixel;
    }
    @media only screen and (max-width: 700px) {
      height: $pixel * 25;
    }
    @media only screen and (max-width: 500px) {
      height: $pixel * 15;
    }
  }

  .products {
    height: $pixel * 50;
    background-color: #ef394e;

    .swiper-slides {
      font-size: 18px;
      background: #fff;
      border-radius: $pixel * 0.6;

      // Center slide text vertically
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .product-img {
      width: $percent * 10;
      height: $percent * 5;
      object-fit: fill;
      border-radius: $pixel * 0.5;
    }
    .product-title {
      height: 57px;
      background-color: aqua;
    }
    .slide-item {
      height: 45 * $pixel;
    }
    .amazing-image {
      width: 180px;
      height: 180px;
    }
    .amazing-text {
      width: 230px;
      height: 230px;
    }
    .percent {
      width: 60px;
    }
    @media only screen and (max-width: 950px) {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
    @media only screen and (max-width: 700px) {
      height: $pixel * 42;

      .percent {
        font-size: 16px;
      }

      .slide-item {
        height: $pixel * 37;
      }
      .amazing-image,
      .amazing-text {
        height: $pixel * 15;
        width: $pixel * 15;
      }
      .product-title {
        height: $pixel * 4.5;
        font-size: 16px;
      }

      .product-discount,
      .product-price {
        height: $pixel * 4;
        font-size: 16px;
      }
      .product-rate {
        font-size: 16px;
      }
    }
    @media only screen and (max-width: 500px) {
      height: $pixel * 27;
      .slide-item {
        height: $pixel * 22;
      }
      .product-rate,
      .product-discount {
        display: none;
      }
      .amazing-image,
      .amazing-text {
        height: $pixel * 8;
        width: $pixel * 10;
      }
      .product-title {
        height: $pixel * 3.9;
        font-size: 16px;
      }
      .percent {
        font-size: 12px;
        width: 30px;
      }
      .product-discount,
      .product-price,
      .product-title {
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .special-product {
    height: $pixel * 40;
    @media only screen and (max-width: 1100px) {
      height: $pixel * 35;
    }
    @media only screen and (max-width: 990px) {
      height: $pixel * 25;
    }
    @media only screen and (max-width: 575px) {
      height: $pixel * 28;
    }
    @media only screen and (max-width: 450px) {
      height: $pixel * 20;
    }
  }

  .two-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* text-overflow: ellipsis; */
  }
 
  .edit-icon {
    top: 5px;
    right: 10px;
    cursor: pointer;
    // background-color: white;
    border-radius: $pixel * 0.5;
  }
  #editSpecialProductModal {
    .baner-title {
      width: 100px;
    }
    .baner-line {
      width: 90%;
      border-bottom: 1px solid;
    }
  }
}
.product-section-tab {
  .active {
    background-color: transparent !important;
    color: black !important;
  }
  .nav-link {
    color: black !important;
  }
  .nav-link:hover {
    color: $red;
  }
  .nav-item .active {
    border-radius: 0;
    border-bottom: 2px solid $red;
  }
}
