.footer {
  padding: 15px;
  background-color: darkslateblue;
  color: white !important;
  @media (max-width: 992px) {
    .item,
    .share-text,
    .email,
    .share-text {
      font-size: 12px !important;
      // background-color: #EF394E;
    }
    .send-email,
    .email-input {
      font-size: 13px;
      height: 30px !important ;
    }
  }
  @media screen and (max-width: 600px) {
    .footer-b {
      padding-bottom: 60px !important;
    }
    .footer-item {
      display: block !important;
    }
    .w-50 {
      width: 100% !important;
    }
    .support {
      margin-right: 30px;
    }
  }
  @media screen and (max-width: 400px) {
    .inner-item {
      display: block !important;
    }
    .footer-grc {
      width: 100% !important;
    }
    .support {
      margin-right: 0px !important;
    }
  }
  .item {
    // width: 30%;
    div {
      // background-color: #EF394E;
      padding: 2px 0;
    }
    ul {
      li {
        list-style-type: none;
      }
    }
  }
  a {
    text-decoration: none;
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
  .email-input,
  .send-email {
    height: 50px;
    border-radius: 10px;
    text-align: right;
  }
}
