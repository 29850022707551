.allStyles {
  .financial-tabs {
    .fs-14 {
      font-size: 14px;
    }
    .fs-10 {
      font-size: 10px;
    }
    .rounded-10 {
      border-radius: 10px;
    }
    .bg-gray {
      background-color: rgb(236, 231, 231);
    }
    .nav-tabs .nav-link.active {
      border: none;
      border-bottom: 2px solid #203b8c;
    }
  }

  .transaction {
    .bg-blue {
      background-color: #457b9d !important;
    }

    @media screen and (max-width: 1100px) {
      .item {
        width: 50%;
        border-bottom: 1px dashed white;
        /* border-bottom:1px dashed white; */
        display: flex;
        justify-content: space-between;
      }
      .inner-item {
        width: 50%;
        display: inline;
        margin: 0 !important;
      }
      .border1000-1 {
        border-radius: 10px 0 0 0;
      }
      .border1000-2 {
        border-radius: 0 !important;
      }
      .border1100-1 {
        border-radius: 0 0 10px 0 !important;
      }
    }
    @media screen and (max-width: 750px) {
      .item {
        width: 100%;
        /* border-bottom:1px dashed white; */
      }
      .border1000-1 {
        border-radius: 0;
      }
      .border1000-2 {
        border-radius: 0 0 10px 0 !important;
      }
      .border1100-1 {
        border-radius: 0 !important;
      }
      .modal-content {
        width: 95% !important;
        margin: 5% auto;
      }
    }
    @media screen and (max-width: 480px) {
      .inner-item {
        padding: 5px !important;
      }
    }
  }
}

.orders {
  .p-items {
    width: 100% !important;
  }
  .box-items {
    width: 120px !important;
    margin-right: 10px !important ;
    text-align: right;
    flex-grow: 1 !important;
    @media (max-width: 991px) {
      width: 150px !important;
    }
    @media (max-width: 463px) {
      width: 130px !important;
    }
    @media (max-width: 443px) {
      width: 200px !important;
    }
  }
  .box-items-date {
    text-align: right !important;
    width: 20% !important;
    margin-right: 50px !important ;
    flex-grow: 1 !important;
    @media (max-width: 991px) {
      width: 150px !important;
    }
    @media (max-width: 463px) {
      width: 130px !important;
    }
    @media (max-width: 443px) {
      width: 200px !important;
    }
  }
  .lb-items {
    white-space: nowrap;
    width: 26% !important;
    font-size: 13px;
    @media (max-width: 443px) {
      width: 40px !important;
      text-align: right !important;
    }
  }
  .val-items1 {
    white-space: nowrap;
    width: 50% !important;
    font-size: 13px;
    margin-right: 50px;
    @media (max-width: 443px) {
      width: 100px !important;
    }
  }
  .val-items2 {
    white-space: nowrap;
    width: 50% !important;
    font-size: 13px;
    margin-right: 7px;
    @media (max-width: 443px) {
      width: 100px !important;
    }
  }
  .val-items3 {
    white-space: nowrap;
    width: 50% !important;
    font-size: 13px;
    margin-right: 14px;
    @media (max-width: 991px) {
      margin-right: 50px !important;
    }
    @media (max-width: 443px) {
      width: 100px !important;
    }
  }
  .val-items4 {
    white-space: nowrap;
    width: 50% !important;
    font-size: 13px;
    margin-right: 27px;
    @media (max-width: 443px) {
      width: 100px !important;
      margin-right: 50px;
    }
  }
  .bg-blue {
    background-color: #457b9d !important;
  }
  .fs-12 {
    font-size: 12px;
  }
  @media screen and (max-width: 992px) {
    .item {
      width: 50%;
      /* border-bottom:1px dashed white; */
    }
    .border1000-1 {
      border-radius: 10px 0 0 0 !important;
    }
    .border1000-2 {
      border-radius: 0 !important;
    }
  }
  @media screen and (max-width: 560px) {
    .item {
      width: 100%;
      /* border-bottom:1px dashed white; */
    }
    .border1000-1 {
      border-radius: 0 !important;
    }
    .border600-1 {
      border-radius: 10px 10px 0 0 !important;
    }
    // .border1000-2 {
    //   border-radius: 0 0 10px 0 !important;
    // }
  }
}
