.container-item3 {
  // width: fit-content;
  .text-start {
    height: 225px;
  }
  .item {
    margin: 10px 5px;
    width: 300px;
    background-color: #f1f0f5;
    border-radius: 20px;
  }
  .main-text {
    width: 85%;
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .img-gradient {
    bottom: -9px;
    width: 100%;
    height: 244px;
    background-image: linear-gradient(
      rgba(184, 184, 184, 0.507) 43%,
      rgba(5, 5, 5, 0.925) 66%
    ) !important;
    opacity: 0.5;
    border-radius: 20px;
  }
  .img {
    width: 80%;
    height: 221px;
  }
  .img,
  .text,
  .item-img {
    border-radius: 20px;
  }

  @media only screen and (max-width: 1220px) {
    .item {
      width: 250px;
    }
  }
  @media only screen and (max-width: 1100px) {
    .item {
      width: 240px;
    }
  }
  @media only screen and (max-width: 990px) {
    .item {
      width: 270px;
    }
  }
  @media only screen and (max-width: 850px) {
    .item {
      width: 250px;
    }
  }
  @media only screen and (max-width: 770px) {
    .item {
      width: 285px;
    }
  }
  @media only screen and (max-width: 600px) {
    .item {
      width: 240px;
    }
    .two-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 600px) {
    .item {
      width: 240px;
    }
    .img {
      height: 180px;
    }
    .img-gradient {
      height: 205px;
    }
    .two-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 600px) {
    .item {
      width: 220px;
    }
    .img {
      height: 180px;
    }
    .img-gradient {
      height: 205px;
    }
    .two-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 500px) {
    .item {
      width: 190px;
    }
    .main-text {
      display: none;
    }
    .text-start {
      height: 165px;
    }
    .img {
      height: 150px;
    }
    .img-gradient {
      height: 175px;
    }
    .two-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px !important;
    }
    .cont,
    .date {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 400px) {
    .item {
      width: 150px;
    }

    .main-text {
      display: none !important;
    }
    .text-start {
      height: 145px;
    }
    .img {
      height: 120px;
    }
    .img-gradient {
      height: 145px;
    }
    .two-line {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px !important;
    }
    .cont {
      font-size: 12px;
    }
  }
}
