.add-container{


  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  .inputModal2 {
    width:100%;
    margin-bottom: 15px;
    outline: none;
    
  }
  .inputModal2 div {
    background-color: #D9DEDA;
    outline: none;
    border: #D9DEDA 1px solid;
    border-radius: 13px;
    outline: none;
    padding: 2px;
  }
  .rmdp-container .rmdp-input {
    background-color: #D9DEDA !important;
    outline: none;
    border: none;
    margin: 0px 0  15px 0!important;
    border-radius: 13px !important;
    height: 47px !important;
    width: 100% !important;
    padding: 10px !important;
    text-align: right;
  }
  .boxDate {
    width: 100%;
  }
  .boxDate .rmdp-container {
    width: 100%;
  }
  
  .textDateBox {
    width: 50%;
    text-align: start;
    margin: auto;
  }
  .textBox {
    width: 100%;
    text-align: start;
    margin: auto;
  }
  .textsmall{
    font-size: 14px;
    color: #333;
  }}