// .user-report-container {
// }
.user-management-main-container {
  .fs-12 {
    font-size: 12px !important;
  }
  .tab-content {
    background-color: white;
    padding: 20px;
    width: 100%;
  }
  .user-information-container {
    // background-color: rgb(55, 111, 163);
    color: white;
    border-radius: 10px;
    width: fit-content;
    // &:hover {
    //   background-color: rgb(45, 96, 143);
    // }
    .user-information-item {
      border: 1px solid white;
      flex-grow: 1;
    }
  }
  .user-label {
    width: 100%;
    margin: 7px 0 0 0;
    outline: none;
  }
  .user-label div {
    background-color: #8aa9e9;
    outline: none;
    border: #8aa9e9 1px solid;
    border-radius: 10px;
    outline: none;
  }
  .group-change-label {
    width: 100%;
    margin: 7px 0 0 0;
    outline: none;

    div {
      background-color: #f5f7e6;
      outline: none;
      border: #f5f7e6 1px solid;
      border-radius: 10px;
      outline: none;
    }
  }
  .user-management-main-container {
    background-color: white;
    border-radius: 7px;
    border: 1px solid rgba(128, 128, 128, 0.459);
    margin: 0 10px;
  }
  .card-item-tabs {
    width: 100%;
  }
  .user-information-container {
    width: 100%;
  }
  .user-information-item {
    .icon {
      fill: black;
      width: max-content;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 576px) {
    .user-information-container {
      // width: 300px;
    }
    .user-information-item {
      width: 200px !important;
      margin: 0 auto;
      border: none;
    }
  }
  .edit-user-container {
    .edit-user-item {
      .rmdp-container {
        width: 50%;
      }
      input.rmdp-input {
        width: 100%;
        padding: 17px;
      }
      margin: 20px;
      input:not(.rmdp-input),
      select {
        width: 50%;
      }
    }
  }

  .card-item-tabs {
    &.is-dark-blue {
      background-color: #133b5c;
    }
    text-align: center;
    border-radius: 10px;
    background-color: #668cd9;
    // border: 1px solid #707070;
    margin-top: 30px;
    text-align: center;
    .title {
      color: white;
      padding: 0 10px;
      border-bottom: 1px solid white;
      padding-bottom: 10px;
    }
    .description {
      color: white;
      margin-top: 10px;
      padding: 0 10px;
      text-align: center;
      input:hover {
        cursor: pointer;
      }

      button {
        padding: 0;
        margin: 0;
        text-decoration: none;
        color: white;

        &:hover {
          color: rgb(255, 234, 163);
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
    > div {
      padding: 10px 00px 10px 00px;

      flex-grow: 1;
      border: 1px solid white;
      &:last-of-type {
        border-left: none !important;
      }
      .icon-container {
        svg {
          cursor: pointer;
          margin-top: 25px;
        }
      }
    }
  }

  .link-style {
    text-decoration: none !important;
    &:hover {
    }
  }

  @media screen and (max-width: 420px) {
    .user-management-inner {
      margin: 0 10px !important;
    }
  }
}
