.containerMain {
  width: 100%;
}
.secondry{
  margin-top: 30px;
}
.tlg{
  font-size: 19px;
  margin:  0 8px;
}
.tlg1{
  font-size: 19px;
  margin:  10px 8px -2px 8px;
}
.tlg:hover{
  cursor: pointer;
}
.BlueCol{
  padding: 10px 0px 10px 0px;
  flex-grow: 1;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  
}
.circl{
 color: #3a5ec8;
  font-size: 45px;
  cursor: pointer;
}
.BlueCol:last-of-type {
  border-left: none !important;}
.blueRow{
  border-bottom: 1px solid #fff;
  height: 55px;
  margin-bottom: 0;
  padding: 0 7px;
}
.nameRow{
  height: 55px;
  margin-bottom: 0;
  padding: 0 7px;
}
.sec{
  background-color: #457B9D;
  color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.gry{
  background-color: #DDDBDC;
  color: #333;
}
.spn{
  background-color: #fff;
  color: #333 ;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 13px;
  margin:0 2px;
}
.inputModal{
  background-color: #D9DEDA;
  width: 100%;
  outline: none;
  border-radius: 13px;
  border: 1px solid #D9DEDA;
  padding: 11px;
  margin-bottom:15px ;
}
@media (max-width: 1193px) {
  .containerMain {
    width: 95%;
  }
}
