.Product {
  .eye-icon {
    margin-bottom: 8px;
  }
  .eye-btn {
    width: 35px;
    height: 23px;
    padding: 0;
  }
  a {
    text-decoration: none !important ;
    color: black;
  }
  .item {
    color: black !important;
    text-decoration: none !important;
    margin: 10px;
    width: 250px;
    height: auto;
    border-radius: 20px;
    .item-title {
      border-radius: 20px;
    }
  }
  .main-text {
    width: 239px;
    height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .main-text2 {
    width: 95%;
    height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .img-product {
    height: 195px;
  }
  @media only screen and (max-width: 900px) {
    .item {
      width: 260px;
    }
  }
  @media only screen and (max-width: 600px) {
    .item {
      width: 240px;
    }
  }
  @media only screen and (max-width: 500px) {
    .item {
      width: 180px;
    }
    .img-product {
      width: 150px;
      height: 150px;
    }
    .price {
      font-size: 14px;
    }
    .brand-star,
    .eye-btn {
      display: none !important;
    }
  }
  @media only screen and (max-width: 400px) {
    .img-product {
      width: 100px;
      height: 100px;
    }
    .price {
      font-size: 12px;
    }
    .item {
      width: 150px;
    }
  }
}
