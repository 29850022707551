.containerMain {
  width: 100%;
}
.secondry{
  margin-top: 30px;
}
.tlg{
  font-size: 19px;
  margin:  0 8px;
}
.tlg1{
  font-size: 19px;
  margin:  10px 8px -2px 8px;
}
.tlg:hover{
  cursor: pointer;
}
.BlueCol{
  padding: 10px 0px 10px 0px;
  flex-grow: 1;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  
}
.BlueCol:last-child{

  align-items: stretch;
  
}
.circl{
 color: #3a5ec8;
  font-size: 45px;
  cursor: pointer;
}
.BlueCol:last-of-type {
  border-left: none !important;}
.blueRow{
  border-bottom: 1px solid #fff;
  height: 55px;
  margin-bottom: 0;
  padding: 0 7px;
}
.s{
  background-color: #D9DEDA;
  width:100%;
  border-radius: 13px;
  height: 42px;
}
.btnacardeon2{
position: absolute;
top: 138px;
left:21px;
color: rgb(184, 183, 183);
background-color: #D9DEDA;
border: 1px solid #D9DEDA;
font-size: 23px;

}
.btnacardeon3{
  position: absolute;
  top: 240px;
  left:21px;
  color: rgb(184, 183, 183);
  background-color: #D9DEDA;
  border: 1px solid #D9DEDA;
  font-size: 23px;
  
  }
.stepsContainer{
  background: #efefef;
  padding: 15px;
  border-radius: 13px;
}
.steps{
  border-bottom:1.8px solid #ffffff ;
}
.textRes{
  font-size: 15px;
  font-weight: 600;
}
.texttarikh{
  font-size: 13px;
  margin: 4px 13px;

}
.textdate{
  font-size: 13px;
  margin-top: 4px;
  color: rgb(212, 0, 0);
}
.textname{
  font-size:14px ;
  margin-top: 4px;
  color: #333;}
.bor{
    border-bottom:1.8px solid #ffffff ;
  }
 
  .bor2:not(:last-child){
    border-bottom:1.8px solid #ffffff ;
  }
.nameRow{
  height: 55px;
  margin-bottom: 0;
  padding: 0 7px;
}
.sec{
  background-color: #457B9D;
  color: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}
.gry{
  background-color: #DDDBDC;
  color: #333;
}

.inputModal{
  background-color: #D9DEDA;
  width: 100%;
  outline: none;
  border-radius: 13px;
  border: 1px solid #D9DEDA;
  padding: 11px;
  margin-bottom:15px ;
}
.inputModal3{
  background-color: #D9DEDA;
  width: 100%;
  outline: none;
  border-radius: 13px;
  border: 1px solid #D9DEDA;
  padding: 11px;
  margin-bottom:15px ;
}
@media (max-width: 1193px) {
  .containerMain {
    width: 95%;
  }
}
@media (max-width: 503px) {
  .inputModal3{
    width: 100%;
  }
 
}
@media (max-width: 425px) {
 
  .bor2{
    flex-wrap: wrap;
  }
}
