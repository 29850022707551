$pixel: 10px;
$percent: 10%;
$red: #ef394e;
.hashtags-base {
  width: 97%;

  .banner {
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
    img {
      height: 90%;
    }
    .line {
      border-top-right-radius: 50px;
      border-bottom-left-radius: 50px;
    }
    width: 85%;
    height: 200px;
    background-color: #dc3545;
    @media (max-width: 992px) {
      width: 95%;
      // img {
      //   height: 80% !important;
      // }
    }
    @media (max-width: 700px) {
      width: 95%;
      height: 300px;
      text-align: center;
      img {
        width: 40%;
      }
      div {
        flex-direction: column;
      }
    }
    @media (max-width: 500px) {
      img {
        width: 55%;
        height: auto;
      }
    }
  }

  .hashtag-container {
    overflow-y: auto;
    display: flex;

    div {
      //   width: 90px;
      white-space: nowrap;
      // min-width: 100px;

      //   max-width: fit-content;
      border-radius: 10px;
      cursor: pointer;
    }
    div:hover {
      background-color: #da6772;
      color: white;
    }
    .deactive-hashtag-btn {
      border: 1px solid #dc3545;
      background-color: white;
      color: #dc3545;
    }
    .active-hashtag-btn {
      background-color: #dc3545;
      color: white;
    }
  }
  @media (max-width: 1400px) {
    width: 90%;
  }
  /* width */
  .hashtag-container::-webkit-scrollbar {
    width: 20px;
    height: 4px;
  }

  /* Track */
  .hashtag-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .hashtag-container::-webkit-scrollbar-thumb {
    background: #dc3545 !important;
    border-radius: 10px;
  }

  /* Handle on hover */
  .hashtag-container::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  // firefox scroll style
  .hashtag-container {
    scrollbar-color: $red #e8e8e8;
    scrollbar-width: thin;
  }

  .products-list {
    // height: $pixel * 50;
    .product-img {
      // width: $percent * 10;
      width: 100%;
      // width: fit-content !important;
      // height: $percent * 5;
      height: 300px;

      // object-fit: fill;
      border-radius: $pixel * 0.5;
      img {
        // height: 220px;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .edit-style {
      background-color: rgb(245 216 216 / 80%);
      color: #b61020;
      // cursor: pointer;
    }
    .edit-style button:focus {
      box-shadow: none !important;
    }
    // .edit-style:hover {
    //   background-color: rgba(245, 196, 196, 0.8);
    //   color: #b61020;
    //   font-size: 18px;
    //   font-weight: bold;
    //   padding: 25px 0;
    // }
    .moreinfo:focus {
      box-shadow: none !important;
    }
    .product-title {
      height: 95px;
      margin-bottom: 10px;
    }
    .slide-item {
      height: 62 * $pixel;
      background-color: #73cbcb;
      .buy-once {
        background-color: #cddc39;
        color: white;
      }
      .buy-once:focus {
        outline: none !important;
        box-shadow: none !important;
      }
      .blink {
        animation: blinker 1s linear infinite;
      }
      @keyframes blinker {
        0% {
          opacity: 0.2;
        }

        50% {
          opacity: 1;
        }
        100% {
          opacity: 0.2;
        }
      }
    }
    .product-discount {
      height: $pixel * 1.5;
      font-size: 16px;
    }
    .product-price {
      height: $pixel * 3;
      font-size: 16px;
    }
    @media (max-width: 992px) {
      .product-price {
        height: $pixel * 4;
        font-size: 16px;
      }
      .slide-item {
        height: 59.5 * $pixel;
      }
    }
    @media only screen and (max-width: 700px) {
      // height: $pixel * 42;

      // .percent {
      //   font-size: 16px;
      // }
      // .product-img {
      //   height: 158px;

      //   img {
      //     height: 158px;
      //   }
      // }
      .slide-item {
        // height: $pixel * 57.5;
      }
      // .amazing-image,
      // .amazing-text {
      //   height: $pixel * 15;
      //   width: $pixel * 15;
      // }
      .product-title {
        // height: $pixel * 4.5;
        font-size: 16px;
      }

      // .product-discount,
      // .product-price {
      //   height: $pixel * 4;
      //   font-size: 16px;
      // }
      // .product-rate {
      //   font-size: 16px;
      // }
    }
    @media only screen and (max-width: 500px) {
      // height: $pixel * 27;
      .slide-item {
        height: $pixel * 54;
        .product-title {
          height: 70px;
        }
      }
      .product-rate,
      .product-discount {
        display: none;
      }
      // .amazing-image,
      // .amazing-text {
      //   height: $pixel * 8;
      //   width: $pixel * 10;
      // }
      .product-title {
        // height: $pixel * 8.8;
        font-size: 16px;
      }
      // .percent {
      //   font-size: 12px;
      //   width: 30px;
      // }
      .product-img {
        height: 270px !important;
      }
      .product-img img {
        width: 100% !important;
        height: 220px !important;
      }
      .product-discount,
      .product-price,
      .product-title {
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .search-body {
    display: none !important;
    width: 90% !important;
    @media (max-width: 500px) {
      display: block !important;
    }
  }
}

.five-line {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
}
.four-line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}
