.lead-list {
  .name,
  .mobile {
    border-left: 1px solid #dee2e6;
  }
  .name {
    width: 200px;
  }
  .mobile {
    width: 150px;
  }
  @media (max-width: 500px) {
    .name {
      width: 50%;
    }
    .mobile {
      width: 50%;
      border-left: none;
    }
    .product {
      border-top: 1px solid #dee2e6;
    }
  }
}
