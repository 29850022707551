@media (max-width: 992px) {
  .store .display-input {
    display: block !important;
  }
}

.store .ad-slider {
  width: 75%;
  height: 365px;
}

@media only screen and (max-width: 1100px) {
  .store .ad-slider {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .store .ad-slider {
    height: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .store .ad-slider {
    height: 150px;
  }
}

.store .products {
  height: 500px;
  background-color: #ef394e;
}

.store .products .swiper-slides {
  font-size: 18px;
  background: #fff;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.store .products .product-img {
  width: 100%;
  height: 50%;
  -o-object-fit: fill;
     object-fit: fill;
  border-radius: 5px;
}

.store .products .product-title {
  height: 57px;
  background-color: aqua;
}

.store .products .slide-item {
  height: 450px;
}

.store .products .amazing-image {
  width: 180px;
  height: 180px;
}

.store .products .amazing-text {
  width: 230px;
  height: 230px;
}

.store .products .percent {
  width: 60px;
}

@media only screen and (max-width: 950px) {
  .store .products .swiper-button-next,
  .store .products .swiper-button-prev {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .store .products {
    height: 420px;
  }
  .store .products .percent {
    font-size: 16px;
  }
  .store .products .slide-item {
    height: 370px;
  }
  .store .products .amazing-image,
  .store .products .amazing-text {
    height: 150px;
    width: 150px;
  }
  .store .products .product-title {
    height: 45px;
    font-size: 16px;
  }
  .store .products .product-discount,
  .store .products .product-price {
    height: 40px;
    font-size: 16px;
  }
  .store .products .product-rate {
    font-size: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .store .products {
    height: 270px;
  }
  .store .products .slide-item {
    height: 220px;
  }
  .store .products .product-rate,
  .store .products .product-discount {
    display: none;
  }
  .store .products .amazing-image,
  .store .products .amazing-text {
    height: 80px;
    width: 100px;
  }
  .store .products .product-title {
    height: 39px;
    font-size: 16px;
  }
  .store .products .percent {
    font-size: 12px;
    width: 30px;
  }
  .store .products .product-discount,
  .store .products .product-price,
  .store .products .product-title {
    font-size: 12px;
    text-align: center;
  }
}

.store .special-product {
  height: 400px;
}

@media only screen and (max-width: 1100px) {
  .store .special-product {
    height: 350px;
  }
}

@media only screen and (max-width: 990px) {
  .store .special-product {
    height: 250px;
  }
}

@media only screen and (max-width: 575px) {
  .store .special-product {
    height: 280px;
  }
}

@media only screen and (max-width: 450px) {
  .store .special-product {
    height: 200px;
  }
}

.store .two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.store .edit-icon {
  top: 5px;
  right: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.store #editSpecialProductModal .baner-title {
  width: 100px;
}

.store #editSpecialProductModal .baner-line {
  width: 90%;
  border-bottom: 1px solid;
}

.product-section-tab .active {
  background-color: transparent !important;
  color: black !important;
}

.product-section-tab .nav-link {
  color: black !important;
}

.product-section-tab .nav-link:hover {
  color: #ef394e;
}

.product-section-tab .nav-item .active {
  border-radius: 0;
  border-bottom: 2px solid #ef394e;
}
