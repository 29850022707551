.customer-message {
  $pixel: 10px;
  $blue: #203b8c;
  border-radius: $pixel * 2.5;
  .operation-btn-container {
    border: 1px solid $blue;

    .operation-btn {
      color: $blue;
    }
    .border-operation-btn {
      border-left: 1px solid $blue;
    }
    .operation-btn:hover {
      background-color: $blue;
      color: white;
    }
  }
}

.message-line-height {
  line-height: 2;
  font-size: 15px;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}
