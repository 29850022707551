#blogknow-img {
  background-image: url(../../assets/images/Mask\ Group\ 7.png);
  min-height: 290px;
  opacity: 0.9;
}
.rounded-10 {
  border-radius: 10px;
}
.pointer {
  cursor: pointer;
}
.fs-12 {
  font-size: 12px;
}
/* @media screen and (max-width:600px){
    .blogknow{
        width:80px !important;
    }
} */
@media screen and (min-width: 770) {
  .first-blog-section-container .pic-3 {
    height: 250px !important;
  }
}
