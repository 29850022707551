.navbar-style {
  @media (max-width: "992px") {
    background-color: white !important;
  }
  background-color: #f8f7fe;
  box-shadow: 10px;
  .navbar_links > .nav-item,
  .user-link {
    text-align: center;
    margin: 0 10px;
    // width: 100px;
    border-radius: 40px;
    a {
      color: black !important;
    }
    &:hover {
      // background-color: #5f7ed9;
      a {
        color: white !important;
      }
    }
  }

  .navbarSupportedContent {
    width: 20% !important;
    background-color: black !important;
  }
  .search-input {
    @media (max-width: "500px") {
      display: none !important;
    }
  }
  .exit-button {
    @media screen {
    }
  }
}
.navbar {
  background-color: #f6f6fe !important;
  .dropdownToggle {
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    font-size: 14px !important;
  }
  .dropdownToggle:focus {
    box-shadow: none !important;
    background-color: #dc3545 !important;
    color: white !important;
  }
  .drop-down {
    .drop-down-item {
      text-decoration: none !important;
      color: black !important;
    }
    a,
    div {
      text-decoration: none !important;
      color: black !important;
    }
    .drop-down-item:hover {
      background-color: white;
      a,
      div {
        color: #dc3545 !important;
      }
    }
    .drop-down-active {
      background-color: #dc3545;
      a,
      div {
        color: white !important;
      }
    }
  }
  .navbar-toggler-right:focus {
    box-shadow: none !important;
  }
}

.menu-mobile {
  width: 250px;
  height: auto;
  position: fixed;
  z-index: 10;
  background-color: white;
  // background-color: #ed9273;

  right: 0;
  border-left: 1px solid #dc3545;
  border-top: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;

  border-radius: 5px;
  overflow-y: scroll;
  height: 100vh;
  bottom: 0;

  .ul-container {
    list-style-type: none !important;
    display: flex;
    flex-direction: column;

    .li {
      text-decoration: none;
      padding: 5px 0;
      cursor: pointer;
      margin-left: 2rem;
      margin-right: 2rem;
      padding-right: 15px;
      border-bottom: 1px solid #f5baad;
      color: rgb(29, 28, 28) !important;
    }
    .panel-title {
      margin-left: 0.3rem;
      margin-right: 0.3rem;
      background-color: #f59883;
      color: white;
      box-shadow: 1px 1px 8px 1px #e5bdbd;
    }
    .active-item {
      background-color: #dc3545 !important;
      color: white !important;
      border-radius: 5px;
    }
  }

  .left-side1 {
    // align-items: center;
    position: absolute;
    left: 4px;
    border-bottom: none !important;
    .nav-link {
      position: relative;
      font-size: 18px !important;
      display: inline-block;
      .cart {
        position: absolute;

        color: #f6f6fe;
        font-size: 10px;
        left: 50%;
        padding: 0px 8px 0px 8px;
        border-radius: 15px;
        background-color: #dc3545;
        cursor: pointer;
      }
    }
  }
}

/* width */
.menu-mobile::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
.menu-mobile::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.menu-mobile::-webkit-scrollbar-thumb {
  background: #dc3545 !important;
  border-radius: 10px;
}

/* Handle on hover */
.menu-mobile::-webkit-scrollbar-thumb:hover {
  background: #9c2308;
}
@media (max-width: 991px) {
  .msMobile {
    margin-right: 0 !important;
  }
}
