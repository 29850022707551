.widthDisplay {
  display: block;
}
.userProfile {
  width: 25%;
  height: 330px;
  border-radius: 25px;
  border: 1px solid rgb(223, 223, 223);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.sidebarInfo {
  border-radius: 18px;
  padding: 5px 10px;
  width: 150px;
  height: 40px;
  text-align: center;
}
.sidebarLink {
  width: 160px;
  height: 40px;
  font-size: 18px;
  border-radius: 18px;
  padding-right: 13px;
}
.sidebarLink:hover {
  background-color: #818fb9;
  cursor: pointer;
}
.imgCancled {
  width: 100px;
  height: 100px;
}
.orders {
  justify-content: space-around;
  border-radius: 25px;
  border: 1px solid rgb(223, 223, 223);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  width: 85%;
}
.messages {
  border-radius: 25px;
  border: 1px solid rgb(223, 223, 223);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  width: 85%;
}
.tableContainer {
  width: 95%;
  margin: auto;
  /* margin: 10px 24px 10px 55px; */
  margin-top: 70px;
  border-radius: 17px !important;
  font-size: 15px;
  color: #707070 !important;
  background-color: #dddbdc;
  display: none;
}
.sotun {
  text-align: right;
  border: none;
  border-radius: 7px !important;
  color: rgb(73, 73, 73);
}
.sotun th {
  border: 1px solid rgb(255, 255, 255);
  padding: 8px;
}
.sotun td {
  border: 1px solid rgb(253, 253, 253);
  padding: 8px;
}
.textSuggest {
  color: rgb(112, 112, 112);
  font-size: 15px;
}
.colorSuggest {
  color: #eca72c;
}
.cancledBox {
  width: 120px;
  height: 230px;
  margin: 2px;
  align-items: center;
}
.userDetail {
  height: 100px;
}
.modalBtn {
  background-color: #ffffff;
  border: none;
  outline: none;
}
.messageIcon {
  background-color: rgb(218, 43, 95);
  padding: 8px;
  border-radius: 12px;
  width: 40px;
}
.mobilePic {
  display: none;
}
.editInfo {
  display: none;
}
@media (max-width: 991px) {
  .sidebarr {
    position: fixed !important;
    height: 100% !important;
  }
  .userProfile {
    width: 30%;
    height: 330px;
    border-radius: 25px;
    border: 1px solid rgb(223, 223, 223);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}
@media (max-width: 586px) {
  /* .mobilePic {
    display: block;
    position: absolute;
    right: 50%;
    top: 1%;
    transform: translate(50%, 62%);
  } */
  .editInfo {
    display: block;
    cursor: pointer;
  }
  .tableContainer {
    display: block;
  }
  .widthDisplay {
    display: none;
  }
  .imgCancled {
    width: 70px;
    height: 70px;
  }
  .orders {
    font-size: 13px;
    width: 95%;
  }
  .cancledBox {
    height: 180px;
  }
  .exprinceText {
    font-size: 16px;
  }
  .textSuggest {
    font-size: 14px;
  }
  .messageIcon {
    background-color: rgb(218, 43, 95);
    padding: 5px;
    border-radius: 12px;
    width: 29px;
  }
  .headphone {
    width: 60px;
  }
  .colorSuggest {
    font-size: 14px;
  }
  .messages {
    width: 95%;
  }
}
@media (max-width: 421px) {
  .orders {
    flex-wrap: wrap;
  }
  .cancledBox {
    width: 170px;
    margin: 2px;
    align-items: center;
  }
}
@media (max-width: 396px) {
  .mobilePic {
    transform: translate(56%, 55%);
  }
}

.twoLine {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
