.cards-numbers {
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    div {
      font-size: 14px !important;
    }
    img {
      height: 40px !important;
    }
  }
}
