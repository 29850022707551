.mobile {
  // box-shadow: 10px !important;
  width: 100%;
  @media (max-width: "600px") {
    .mobile-style {
      display: block !important;
      .item-container {
        flex-grow: 1;

        .active {
        }
        label {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: "400px") {
    .mobile-style {
      width: 100% !important;
    }
  }
  .item-margin {
    // margin:0 15%;
  }

  .item {
    border: none !important;
    font-size: 12px;
  }
  .active .item {
    background-color: #0b5ed7;
    color: white;
  }
}
