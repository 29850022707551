.customer-message {
  border-radius: 25px;
}

.customer-message .operation-btn-container {
  border: 1px solid #203b8c;
}

.customer-message .operation-btn-container .operation-btn {
  color: #203b8c;
}

.customer-message .operation-btn-container .border-operation-btn {
  border-left: 1px solid #203b8c;
}

.customer-message .operation-btn-container .operation-btn:hover {
  background-color: #203b8c;
  color: white;
}

.message-line-height {
  line-height: 2;
  font-size: 15px;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}
