.single-product {
  .slider-product {
    .big-image {
      width: 80%;
      margin-left: 30px !important;
      // margin-right: 10px;
    }
    .thumbnails-container {
      width: 125px;
    }
    @media only screen and(max-width:600px) {
      .thumbnails-container {
        width: 90%;
      }
    }
  }
  .validation-txt {
    font-size: 12px;
    color: red;
  }

  $pixel: 10px;
  .bg-navy {
    background-color: navy;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .round {
    border-radius: $pixel * 1.5;
  }
  .img-slider {
    width: 120px;
    height: 100px;
    border-radius: 20px;
    cursor: pointer;
  }
  .slides-height {
    max-height: 300px;
  }
  .slick-track {
    height: 300px !important;
  }
  .swiper {
    height: 400px;
  }

  @media only screen and(max-width:991px) {
    .slider-product {
      border: none !important;
    }
  }
  @media only screen and(max-width:600px) {
    .slides-height {
      height: 150px;
    }
    .slider-product {
      flex-direction: column !important;
    }
    .slider-tab {
      flex-direction: row !important;
      width: 90%;
    }
    .slider-content {
      width: 100% !important;
    }
  }
}

.main-title {
  .title {
    width: 15%;
    background-color: #ef394e;
    color: white;
    text-align: center;
    height: 64px;
    border-top-left-radius: 60px;
    padding: 12px;
    padding-right: 0 !important;
    font-size: 22px;
  }
  .line {
    width: 100% !important;
    height: 3px !important;
    background-color: #ef394e;
  }
  @media (max-width: 1189px) {
    .title {
      width: 25% !important;
      font-size: 20px;
    }
  }

  @media (max-width: 555px) {
    .title {
      width: 40% !important;
      font-size: 18px;
    }
  }
}

#editProductModal,
#editProductModalUnique,
.addproduct-style {
  .fs-14 {
    font-size: 14px;
  }
  // .modal-style {
  //   width: 100% !important;
  //   .modal-dialog {
  //     max-width: 1500px !important;
  //     width: 95% !important;
  //   }
  // }
  #discountDate,
  #discountDatePartner {
    // width: 150px !important;
    height: 36px !important;
  }
  .validation-txt {
    font-size: 12px;
    color: red;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .main-price-item {
    width: 33.33% !important;
  }
  .rmdp-container {
    width: 100% !important;
  }
  .color-container,
  .inventory-container {
    width: 47%;
  }
  .nav-link.active {
    color: rgb(28, 28, 189) !important;
  }
  .nav-link {
    color: rgb(45, 45, 46) !important;
  }
  .title,
  .brand,
  .cover,
  .other-media {
    width: 50%;
  }

  @media only screen and(max-width:500px) {
    .price-date {
      flex-wrap: wrap;
    }

    .color-container {
      width: 100%;
    }
    .inventory-container {
      width: 90%;
    }
    #discountDate,
    #discountDatePartner {
      width: 100% !important;
    }
    .main-price-item {
      width: 100% !important;
    }
    .first-container,
    .second-container {
      flex-wrap: wrap;
    }
    .title,
    .brand,
    .cover,
    .other-media {
      width: 100%;
    }
  }
}
@media screen and (max-width: 992px) {
  .addproduct-style {
    .modal-style {
      width: 100% !important;
    }
    .modal-dialog {
      max-width: 1500px !important;
      width: 95% !important;
    }
  }
}
.comments,
.comment-list {
  $blue: #586ba6;
  $darkblue: #203b8c;
  $lightgray: #f2f0f1;

  border: none !important;
  background-color: none !important;
  border-radius: 10px !important;
  width: 85% !important
  ;
  .card-header {
    padding: 10px !important;
  }
  .validation-txt {
    font-size: 12px;
    color: red;
    margin-right: 10px;
  }

  .round-top {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }
  .round-bottom {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
  .btn-color {
    background-color: $blue;
  }
  .btn-color:hover {
    background-color: $darkblue;
  }
  .bg-color-blue {
    background-color: $darkblue;
  }
  .bg-color-gray {
    background-color: $lightgray;
  }
  .title-style {
    font-size: 14px;
    color: rgb(99, 98, 98);
    min-width: 70px;
  }
  .fs-14 {
    font-size: 14px;
  }
  @media only screen and(max-width:600px) {
    .comment-title-rate {
      flex-direction: column-reverse;
    }
    .comment-title {
      width: 90% !important;
    }
  }
}
