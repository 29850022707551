.Tabs{
    .tab-pane-hover:hover{
        cursor: pointer;
    }
    .padding-x{
        margin: auto;
        width: fit-content;
    }
    
    @media screen and (max-width: 576px) {
        padding: 0!important;
    }
}