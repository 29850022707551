
.topimg{
    position: absolute;
    top: 0px;
    left: 0px;
    width:100%;
    height:100%;
    background-image: url('../../assets/images/bgblog1.png');

    /* background-image:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.5)); */
}
.text-darkblue{
    color:#1c2140;
}