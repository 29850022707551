.search-container {
  position: relative;
  width: 45%;
  .search-result-container {
    position: absolute;
    z-index: 10;
    top: 100%;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    background-color: white;
    border-radius: 0px 0px 7px 7px;
    border: 1px solid gainsboro;
    border-top: none;
    padding: 10px 5px 10px 5px;
  }
  .search-result-item-container {
    border-bottom: 1px solid rgb(236, 233, 233);
    padding-bottom: 5px;
    margin-bottom: 7px;
    // border-bottom-left-radius: 60%;
    .title-section {
      color: #313333;
    }
  }
  .value-container {
    padding: 5px 7px;
    &:hover {
      background-color: rgba(237, 243, 243, 0.507);
    }
    .value {
      font-weight: bold;
    }

    margin: 10px 0px;
  }
  a:not(:last-child) > .value-container {
    border-bottom: 1px solid rgba(207, 202, 202, 0.404);
    border-radius: 5px 5px 0 0;
  }
  .title {
    color: #2e94a1;
    // font-weight: bold;
  }
  .left-arrow {
    color: #313333;
    cursor: pointer;
  }
}
