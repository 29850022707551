.single-article-container {
  .se-menu-list {
    float: right !important;
  }
  .blog-slider-container {
    .blog-slider-item {
      max-width: 500px;
      max-height: 500px;
      .blog-slider-image {
        width: 400px;
        height: 300px;
      }
    }
  }
  .slick-arrow.slick-next {
    display: none !important;
  }
}
