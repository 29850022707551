.group-view {
  .swiper-container {
    width: 480px;
  }

  @media screen and (min-width: 640px) {
    .swiper-container {
      width: 640px;
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
  }
  .main-title {
    .title {
      width: 15%;
      color: white;
      text-align: center;
      height: 64px;
      border-top-left-radius: 60px;
      padding: 12px;
      padding-right: 0 !important;
      font-size: 10%;
    }
    .line {
      width: 100% !important;
      height: 3px !important;
    }
    @media (max-width: 1189px) {
      .title {
        width: 25% !important;
        font-size: 8%;
      }
    }
    @media (max-width: 555px) {
      .title {
        width: 40% !important;
        font-size: 5%;
      }
    }
    // @media (max-width: 600px) {
    //   display: none !important;
    // }
    .container-item2-sm {
      @media (max-width: 600px) {
        display: block !important;
      }
    }
    .display-container-item2,
    .display-container-item3 {
      @media (max-width: 600px) {
        display: none !important;
      }
    }
  }
}
.group-view-mobile .nav-item {
  margin-bottom: 10px !important;
}
.group-view-mobile .nav-item .nav-link {
  margin: 5px;
  color: black !important;
}
.group-view-mobile .nav-item .active {
  border-bottom: 2px solid rgb(199, 23, 55) !important;
}
.edit-list-modal {
  .item-input .rmdp-container input {
    padding: 17px 5px;
    margin-right: 8px;
    width: 234px;
  }
  .item-input label {
    width: 80px;
    height: 60px;
  }
  .my-container {
    width: 234px;
    margin-right: 8px;
  }
}
