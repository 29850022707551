.rmdp-container .rmdp-input {

  border-radius: 4px;
  height: 40px;
  width: 100% !important;
  padding: 5px;
  text-align: right;
  border: 1px solid rgb(214, 214, 214);
}

.boxDate .rmdp-container {
  width: 100%;
}